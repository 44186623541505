<template>
  <b-card-body>
    <div class="mt-4 mb-2 text-center">
      <h4>{{ $t("Related Books") }}</h4>
      <b-card-text>{{ $t("People also search for this items") }}</b-card-text>
    </div>

    <!-- Swiper -->
    <swiper
      class="swiper-responsive-breakpoints px-4 py-2"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(item,index) in items"
        :key="index"
      >
        <b-link :to="{ name: 'book-details', params: { id: item.id } }">
          <div class="item-heading">
            <h5 class="text-truncate mb-0">
              {{ item.title }}
            </h5>
            <small class="text-body">{{ $t("By") }} {{ item.author.title }}</small>
          </div>
          <div class="img-container w-50 mx-auto py-75">
            <b-img
              :src="getImage(item.image)"
              fluid
            />
          </div>
          <div class="item-meta">
            <!-- <ul class="unstyled-list list-inline mb-25">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
              >
                <feather-icon
                  icon="StarIcon"
                  size="18"
                  class="mr-25"
                  :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                />
              </li>
            </ul> -->
            <p class="card-text text-primary mb-0">
              {{ item.title }}
            </p>
            <p class="card-text text-primary mb-0">
              {{ item.year }}
            </p>
            <p class="card-text text-primary mb-0">
              {{ item.size }} {{ $t("MB") }}
            </p>
          </div>
        </b-link>
      </swiper-slide>

      <!-- Add Arrows -->
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper>
  </b-card-body>
</template>

<script>
import {
  BCardBody, BCardText, BImg, BLink,
} from 'bootstrap-vue'
import { filesUrl } from "@/main.js";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    BCardBody,
    BCardText,
    BImg,
    BLink,

    // 3rd Party
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      items:[]
    }
  },
  watch:{
    "$i18n.locale": function (val) {
      this.gS();
    },
  },
  mounted(){
    this.gS()
  },
  computed:{
    category(){
      return this.$store.getters.getCategory
    },
    sub(){
      return this.$store.getters.getSub
    },
    type(){
      return this.$store.getters.getType
    },
  },
  methods:{
    change(){
      this.type = this.$store.getters.getType
      this.category = this.$store.getters.getCategory
      this.subcategory = this.$store.getters.getSub
    },
    async gS() {
      await this.axios
        .get(
          `libraries/client?take=6&skip=0&title=&department_id=&author_id=&category_id=${this.category}&type_id=${this.type}&subcategory_id=${this.sub}&isRandom=true`
          ,{
            headers: {
                "Accept-Language":this.$i18n.locale
            }
          }
        )
        .then((res) => {
          console.log(res)
          this.items = [];
          this.totalRows = "";
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    }

    /* eslint-disable global-require */
    const relatedProducts = [
      {
        name: 'Apple Watch Series 6',
        brand: 'Apple',
        price: 399.98,
        rating: 4,
        image: require('@/assets/images/elements/apple-watch.png'),
      },
      {
        name: 'Apple MacBook Pro - Silver',
        brand: 'Apple',
        price: 2449.49,
        rating: 2,
        image: require('@/assets/images/elements/macbook-pro.png'),
      },
      {
        name: 'Apple HomePod (Space Grey)',
        brand: 'Apple',
        price: 229.29,
        rating: 3,
        image: require('@/assets/images/elements/homepod.png'),
      },
      {
        name: 'Magic Mouse 2 - Black',
        brand: 'Apple',
        price: 90.98,
        rating: 5,
        image: require('@/assets/images/elements/magic-mouse.png'),
      },
      {
        name: 'iPhone 12 Pro',
        brand: 'Apple',
        price: 1559.99,
        rating: 4,
        image: require('@/assets/images/elements/iphone-x.png'),
      },
    ]
    /* eslint-disable global-require */

    return {
      swiperOptions,
      relatedProducts,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
</style>
