<template>
  <section class="app-ecommerce-details">
    <b-alert
      variant="danger"
      :show="item === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'home'}"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <b-card v-if="item" no-body>
      <b-card-body>
        <b-row class="my-2">
          <b-col
            cols="12"
            md="5"
            class="
              d-flex
              align-items-center
              justify-content-center
              mb-2 mb-md-0
            "
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                style="width: 400px; height: 400px; min-height: 15.85rem"
                :src="getImage(item.image)"
                :alt="`Image of ${item.title}`"
                class="product-img image-book-details"
                fluid
              />
            </div>
          </b-col>
          <b-col cols="12" md="7">
            <h2>{{ item.title }}</h2>

            <b-card-text v-if="item.author" class="item-company mb-0">
              <h5>
                <span>{{ $t("By") }}: </span>
                <b-link  :to="{ name: 'author', params: { id: item.author.id } }"
                  
                  class="company-name"
                >
                  {{ item.author.title }}
                </b-link>
              </h5>
            </b-card-text>
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <b-row>
                <b-col v-if="item.department" cols="12">
                  <h5>
                    <span>{{ $t("Department") }}: </span>
                    <b-link 
                      @click.prevent="byDepartment(item.department.id)"
                      class="company-name"
                    >
                      {{ item.department.title }}
                    </b-link>
                  </h5>
                </b-col>
                <b-col v-if="item.category" cols="12">
                  <h5>
                    <span>{{ $t("Category") }}: </span>
                    <b-link
                      @click.prevent="byCategory(item.category.id)"
                      class="company-name"
                    >
                      {{ item.category.title }}
                    </b-link>
                  </h5>
                </b-col>
                <b-col v-if="item.subcategory" cols="12">
                  <h5>
                    <span>{{ $t("Sub Category") }}: </span>
                    <b-link :to="{ name: 'home'}"
                      @click.prevent="bySubCategory(item.subcategory.id)"
                      class="company-name"
                    >
                      {{ item.subcategory.title }}
                    </b-link>
                  </h5>
                </b-col>
                <b-col v-if="item.year" cols="12">
                  <h5>
                    <span>{{ $t("Year") }}: {{ item.year }}</span>
                  </h5>
                </b-col>
                <b-col v-if="item.part" cols="12">
                  <h5>
                    <span>{{ $t("Part") }}: {{ item.part.title }}</span>
                  </h5>
                </b-col>
                <b-col v-if="item.size" cols="12">
                  <h5>
                    <span>{{ $t("Size") }}: {{ item.size }} {{ $t("MB") }} </span>
                  </h5>
                </b-col>
              </b-row>
            </div>
            <b-card-text
              v-if="(item.description !== null) & (item.description !== `null`)"
              >{{ item.description }}</b-card-text
            >
            <ul class="product-features list-unstyled">
              <li>
                <feather-icon icon="ShoppingCartIcon" />
                <span>{{ $t("Free Download") }}</span>
              </li>
              <li>
                <feather-icon icon="DollarSignIcon" />
                <span>0</span>
              </li>
            </ul>

            <hr />

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click.prevent="downloadFile(item.file)"
              >
                <feather-icon icon="DownloadIcon" class="mr-50" />
                <span>{{ $t("Download Now") }}</span>
              </b-button>
              <b-button
                @click="getBook(item.file)"
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-50"
                  :class="{ 'text-danger': false }"
                />
                <span>{{ $t("Read Now") }}</span>
              </b-button>
              <b-dropdown
                variant="outline-secondary"
                no-caret
                toggle-class="btn-icon"
                class="btn-share"
                right
              >
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in [
                    'FacebookIcon',
                    'TwitterIcon',
                    'YoutubeIcon',
                    'InstagramIcon',
                  ]"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <e-commerce-product-details-related-products />
    </b-card>
  </section>
</template>

<script>
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import { filesUrl } from "@/main.js";
import Ripple from "vue-ripple-directive";
import ECommerceProductDetailsItemFeatures from "./ECommerceProductDetailsItemFeatures.vue";
import ECommerceProductDetailsRelatedProducts from "./ECommerceProductDetailsRelatedProducts.vue";
import { useEcommerceUi } from "./useEcommerce";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  computed: {
    // counter() {
    //   return this.$store.getters.changeCounter;
    // },
  },
  data() {
    return {
      item: {},
    };
  },
  mounted() {
    this.gS();
  },
  watch: {
    "$i18n.locale": function (val) {
      this.gS();
    },
    "$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
  },

  methods: {
    async gS() {
      await this.axios
        .get(
          `libraries/client?take=5&skip=0&title=&department_id=&author_id=&category_id=&type_id=3&id=${this.$route.params.id}`,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.item = {};
          this.item = res.data.items[0];
          this.reset();
          if (res.data.items[0].category != null) {
            this.updateCategory(res.data.items[0].category.id);
          }
          if (res.data.items[0].subcategory != null) {
            this.updateSub(res.data.items[0].subcategory.id);
          }
          if (res.data.items[0].type != null) {
            this.updateType(res.data.items[0].type.id);
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    updateDepartment(x) {
      this.$store.commit("setDepartment", x);
    },
    updateCategory(x) {
      this.$store.commit("setCategory", x);
    },
    updateSub(x) {
      this.$store.commit("setSub", x);
    },
    updateType(x) {
      this.$store.commit("setType", x);
    },
    updateAuthor(x) {
      this.$store.commit("setAuthor", x);
    },
    reset() {
      this.$store.commit("reset");
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    getBook(path) {
      window.open(`${filesUrl}${path}`, "_blank");
    },
    byDepartment(id) {
      this.reset()
      this.updateDepartment(id)
      this.$router.push("/");
    },
    byCategory(id) {
      this.reset()
      this.updateCategory(id)
      this.$router.push("/");
    },
    bySubCategory(id) {
      this.reset()
      this.updateSub(id)
      this.$router.push("/");
    },
    byAuthor(id) {
      this.reset()
      this.updateAuthor(id)
      this.$router.push("/");
    },
    async downloadFile(path) {
      await this.axios
        .get(`${filesUrl}/${path}`, {
          headers: {
            "Accept-Language": this.$i18n.locale,
            responseType: "blob",
          },
        })
        .then((res) => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement("x");
          docUrl.href = FILE;
          docUrl.setAttribute("download", "file.pdf");
          document.body.appendChild(docUrl);
          docUrl.click();
        });
    },
  },
  // setup() {
  //   const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

  //   const product = ref(null)

  //   // Remote Data
  //   const fetchProduct = () => {
  //     // Get product  id from URL
  //     const { route } = useRouter()
  //     const productSlug = route.value.params.slug
  //     const productId = productSlug.substring(productSlug.lastIndexOf('-') + 1)

  //     store.dispatch('app-ecommerce/fetchProduct', { productId })
  //       .then(response => {
  //         product.value = response.data.product
  //       })
  //       .catch(error => {
  //         if (error.response.status === 404) {
  //           product.value = undefined
  //         }
  //       })
  //   }

  //   // UI
  //   const selectedColor = ref(null)

  //   fetchProduct()

  //   return {

  //     // Fetched Product
  //     product,

  //     // UI
  //     selectedColor,
  //     handleCartActionClick,
  //     toggleProductInWishlist,
  //   }
  // },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
